import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import Portfolio from '../components/Portfolio'
import EventLight from '../components/EventLight'

const IndexPage = () => (
  
  <Layout>
    {/* seo */}  
    <Seo title="Home" />
    <Hero />
    <Portfolio/>
    <EventLight/>
  </Layout>
)

export default IndexPage


