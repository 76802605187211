
import React, { useState } from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
// sudo npm install --save react-rough-notation  --legacy-peer-deps
import { RoughNotation} from "react-rough-notation"

const Hero = () => {
  const [show, setShow] = useState(true)
  return (
   <HeroContainer> 
    <HeroBg>
            <StaticImage
            src="../assets/images/RG-code-light.jpg"
            alt="Bio"
            placeholder="tracedSVG"
            formats={["auto", "webp", "avif"]}
            quality={40}
            />
    </HeroBg>
     <HeroContent>
        <HeroItems> 
          <HeroItemsH1>  Bonjour, je m'appelle Regina et je suis  </HeroItemsH1>
          <RoughNotation 
            color="yellow"
            type="circle" 
            animationDuration= {4000}
            multiline= {true}
            iterations= {3}
            show={show}>
            <HeroItemsH2>  Développeuse web </HeroItemsH2>
          </RoughNotation>
        </HeroItems>
     </HeroContent>
   </HeroContainer>
  )
}
export default Hero

 {/* Un overlay sombre sur la video avec :before pour mettre en avant le texte  */}
const HeroContainer = styled.div `
  background: #ffe600;
  display: flex;
  justify-content: center ;
  align-items: center;
  width: auto;
  min-height: 103vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;
  color: white;
  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.0) 20%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 0.0) 100%
    ),
    linear-gradient(180deg,rgba(0, 0, 0, 0.2) 0%,transparent 100%
    );
        @media screen and (max-width: 768px) {
            min-height: 100%;
        }
  }
`
const HeroBg = styled.div`
  position: absolute;
  top: 80px;
  bottom: 0px;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
      @media screen and (max-width: 768px) {
      }
`
const HeroContent = styled.div`
  z-index: 3;
  height:  calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px)) / 2;
`
const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: white;
  line-height: 1.1;
`
const HeroItemsH1 = styled.div`
    font-family: BetterGrade;  
    font-size: 7rem; 
    margin-top: 5.5rem;
    margin-bottom: 2rem;
      @media screen and (max-width: 768px) {
          margin-bottom: 1.5rem;
          margin-top: 8rem;
          font-size: 4rem; 
        }
`
const HeroItemsH2 = styled.div` 
    font-size: 6rem; 
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 2rem 5rem;
      @media screen and (max-width: 768px) {
          padding: 0rem 0.5rem;
          font-size: 3rem; 
          margin-top: 2.5rem;
        }
`