import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const Bio = () => {
  return (
    <Portfolio>
   
    <HeroItemsH2>  
    <h1>Coach & Créatrice de sites internet avec des tutos à votre disposition pour que vous soyez indépendant sur votre site web </h1> </HeroItemsH2>

    <HeroItemsH1>  Les dernières réalisations </HeroItemsH1>
    <Portfolio1>
    <BioContainerFlex>
        <BioImage>
            <StaticImage
            src="../assets/images/CG-art.jpg"
            width={500}
            alt="Bio"
            placeholder="tracedSVG"
            quality={40}
            />
        </BioImage>
        <BioButton>
          <h3>Christine Grillet est une artiste peintre Toulousaine 
          <div><h1><button onClick={()=>{navigate("https://gc-art.fr/")}}>www.gc-art.fr</button></h1></div></h3>
          </BioButton>
    </BioContainerFlex>
    <BioContainerFlex>
    <BioImage>
        <StaticImage
        src="../assets/images/Choisir la Lumière.jpg"
        width={500}
        alt="Bio"
        placeholder="tracedSVG"
        quality={40}
        />
    </BioImage>
    <BioButton>
      <h3>Claire, Annie, Béatrice et Martine ont créé ce site pour les femmes abusées
      <div><h1><button onClick={()=>{navigate("https://www.choisirlalumiere.com/")}}>www.choisirlalumiere.com</button></h1></div></h3>
      </BioButton>
</BioContainerFlex>
<BioContainerFlex>
<BioImage>
    <StaticImage
    src="../assets/images/kine-1.jpg"
    width={500}
    alt="Bio"
    placeholder="tracedSVG"
    quality={40}
    />
</BioImage>
<BioButton>
  <h3>Christina est kiné, osthéopathe et experte en médecine chinoise
  <div><h1><button onClick={()=>{navigate("https://christina-grillet.fr/#accueil")}}>www.christina-grillet.fr</button></h1></div></h3>
  </BioButton>
</BioContainerFlex>
</Portfolio1>
<BioButtonFB1>
<HeroItemsH1>  Vos témoignages </HeroItemsH1>
<FB1><button onClick={()=>{navigate("/temoignages")}}>Pourquoi m'ont-ils choisie? </button></FB1>
</BioButtonFB1>
    </Portfolio>
  )
}

export default Bio

const Portfolio = styled.div`
      @media screen and (max-width: 768px) {
      }
`
const Portfolio1 = styled.div`
      @media screen and (max-width: 768px) {
      }
`
const HeroItemsH1 = styled.div` 
    font-size: 6rem; 
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 3rem 5rem;
    padding-bottom: 7rem;
    text-align: center;
      @media screen and (max-width: 768px) {
          padding: 0rem 0.5rem;
          font-size: 3rem; 
          margin-top: 2.5rem;
        }
`
const HeroItemsH2 = styled.div` 
background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.2) 70%,
  rgba(0, 0, 0, 0.0) 100%
),
linear-gradient(180deg,rgba(0.2, 0, 0, 0.0) 0%,transparent 100%
);
    font-size: 1.7rem; 
    margin-top: 0rem;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 5rem 5rem;
    text-align: center;
    h1 {
      font-size: 2.2rem;
      padding-left: 15rem;
      padding-right: 15rem;
      }

      @media screen and (max-width: 768px) {
        padding: 2rem 2rem;
          h1 {
            font-size: 1.5rem;
            padding-left: 0rem;
            padding-right: 0rem;
            }
        }
`
const HeroItemsH3 = styled.div` 
    font-size: 1.3rem; 
    margin-top: 1rem;
    margin-bottom: 10rem;
    font-weight: bold;
    padding: 2rem 5rem;
    text-align: center;
      @media screen and (max-width: 768px) {
          padding: 0rem 0.5rem;
          font-size: 3rem; 
          margin-top: 2.5rem;
        }
`

const BioContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5rem ;
  color: rgba(169, 73, 69, 0.636);
  background-color: #404640; 
  background-color: black;   
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0rem ;
        margin-top: 5rem ;
        background-color: white;  
  `
const BioImage = styled.div`
  flex: 50%;
  text-align: center;
  heigth: auto;
  display: block;
  margin-top: 0rem ;
      @media screen and (max-width: 768px) {

      }
`
const BioButton = styled.div`
  flex: 50%;
  padding: 6rem;
  h3 {
    font-size: 1.2rem;
    color: white;
    padding-top: 2rem;
    padding-right: 4rem;
    text-align: center;
    }
  button {
    padding: 1rem;
    text-decoration: underline;
    margin-top: 30px;
    border-style: none;
    font-size: 1.5rem;
    font-weight: bold;
    background: 
    linear-gradient(
      180deg,
      rgba(255,230,0,0.9) 0%,
      rgba(255,230,0,0.9) 45%,
      rgba(255,230,0,0.1) 100%
      );

    border-radius: 120px / 120px;  
      &:hover {
        background-color: white; 
        transform: translateY(-2px);
        color: #404640;
              }
      }

      @media screen and (max-width: 768px) {
        padding: 1rem;
        h3 {
          color : #404640;
          text-align: center;
          font-size: 1.2rem;
          padding-right: 0rem;
          }
          button{
            background-color: #404640; 
            margin-bottom: 2rem;
            font-size: 1.1rem;
            &:hover {
              background-color: #404640; 
              color: white;
                    }
            }
`
const BioButtonFB1 = styled.div`
  flex: 50%;
  padding: 0rem;
  button {
    padding: 1rem;
    margin-top: 30px;
    border-style: none;
    font-size: 2.5rem;
    font-weight: bold;
    background: 
    linear-gradient(
      180deg,
      rgba(255,230,0,0.9) 0%,
      rgba(255,230,0,0.9) 45%,
      rgba(255,230,0,0.1) 100%
      );

    border-radius: 120px / 120px;  
      &:hover {
        background-color: white; 
        transform: translateY(-2px);
        color: #404640;
              }
      }

      @media screen and (max-width: 768px) {
        padding: 1rem;
        h3 {
          color : #404640;
          text-align: center;
          font-size: 1.2rem;
          }
          button{
            background-color: #404640; 
            margin-bottom: 2rem;
            &:hover {
              background-color: #404640; 
              color: white;
                    }
            }
          
`
const FB1 = styled.div`
display: flex;
flex-direction: column;
text-align: left;
background-color: #031c04;
padding: 3rem;
margin-bottom:5rem;
border-radius: 120px / 120px;  
  @media screen and (max-width: 768px) {
    text-align: center;
}
`