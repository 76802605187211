import React from 'react'
import styled from 'styled-components'
import { FaFacebook } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { Link } from "gatsby"
import { navigate } from "gatsby"

const Event = () => {
  return (
   <EventContainerFlex> 
   <TopLIne>  Me contacter  </TopLIne>
      <ContainerFlex> 
            <div> <button onClick={()=>{navigate("/email")}}>Ecris-moi </button>
            </div>
      </ContainerFlex>
 
      <ContainerFlex3> 
      <LK>
      <Link href="https://www.linkedin.com/in/regine-garcia/" rel="noreferrer noopener" target="_blank"> <FaLinkedin /> </Link>
      </LK>
      <INST>
          <Link href="https://www.instagram.com/rg31_toulouse/" rel="noreferrer noopener" target="_blank"> <FaInstagramSquare /> </Link>
      </INST>
      <FB>
          <Link href="https://www.facebook.com/RG31o/" rel="noreferrer noopener" target="_blank"> <FaFacebook /> </Link>
      </FB>  
</ContainerFlex3> 
      
   </EventContainerFlex>
  )
}

export default Event

 {/* Un overlay sombre sur la video avec :before pour mettre en avant le texte  */}
const EventContainerFlex = styled.div `
display: flex;
flex-direction: row;
justify-content: space-between;
padding: 0rem 5rem;
  background: rgba(0,0,0, 0.636);
  align-items: center;
  width: auto;
  color: white;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0rem ;
    padding: 0rem ;
  }
`
const TopLIne = styled.h1`
    text-align: center;
    font-family: BetterGrade;  
    font-size: 10rem; 
    color : white;
      @media screen and (max-width: 768px) {
        font-size: 6rem; 
        margin: inherit;
        padding: inherit;
      }
`
const ContainerFlex = styled.div `
display: flex;
flex-direction: column;
text-align: center;

    button {
      padding: 25px 30px;
      margin-top: 30px;
      margin-bottom: 60px;
      border-style: none;
      font-size: 35px;
      color: white;
      font-weight: bold;
      background-color: #b51111 ;
      border-radius: 120px / 120px;  
      &:hover {
        background-color: white; 
        transform: translateY(-2px);
        color: #404640;
              }
    }
        @media screen and (max-width: 768px) {
        }

  `
  const ContainerFlex3 = styled.div `
display: flex;
flex-direction: column;
text-align: center;
      a{
        font-size: 30px;
        text-decoration: none;
        text-align: left;
        color : white;
        &:hover {
          color:aqua;
          transition: 0.3s ease-out;  
          cursor: pointer;
        }
              @media screen and (max-width: 768px) {
                font-size: 20px;
              }
  `
const FB = styled.div`
display: flex;
flex-direction: column;
text-align: left;
a{
  font-size: 60px;
  margin-bottom: 2rem;
  &:hover {
    transition: 0.3s ease-out;  
    cursor: pointer;
}
    @media screen and (max-width: 768px) {
      a{
        font-size: 50px;
      }
}
`
const INST = styled.div`
display: flex;
flex-direction: column;
text-align: left;
a{
  font-size: 60px;
  margin-bottom: 2rem;
  &:hover {
    transition: 0.3s ease-out;  
    cursor: pointer;
    color: #d83d9f;
}
    @media screen and (max-width: 768px) {
      a{
        font-size: 50px;
      }
}
`
const LK = styled.div`
display: flex;
flex-direction: column;
text-align: left;
a{
  font-size: 60px;
  margin-bottom: 2rem;
  &:hover {
    color: #86b1ee;
    transition: 0.3s ease-out;  
    cursor: pointer;
}
    @media screen and (max-width: 768px) {
      a{
        font-size: 50px;
      }
}
`
